<template>
  <div class="bg_home">
    <v-row class="pt-10" no-gutters>
      <v-col cols="12" class="px-8">
        <v-window v-model="step" touchless="true">
          <v-window-item :value="0" style="height:320px">
            <v-form ref="registerForm1">
              <v-card-text class="ma-0 pa-0">
                <app-form-field :enabledLabel=true v-model.trim="registerObj.memberCode" :label="$t(`field.username`)"
                                :hint="$t(`fieldHint.fillUpHere`)" :tooltip="$t(`fieldHint.username`)"
                                :rules="validator.usernameRules()"></app-form-field>
                <app-form-field :enabledLabel=true v-model.trim="registerObj.password" :label="$t(`field.password`)"
                                :hint="$t(`fieldHint.fillUpPwdHere`)" :tooltip="$t(`fieldHint.password`)"
                                :rules="validator.passwordRules()" :allowShowPassword="false"
                                type="password"></app-form-field>
                <app-form-field :enabledLabel=true v-model.trim="confirmedPassword" :label="$t(`field.confirmPassword`)"
                                :hint="$t(`fieldHint.confirmPassword`)" :rules="validator.passwordRules()"
                                :allowShowPassword="false" type="password"></app-form-field>
              </v-card-text>
              <v-row no-gutters>
                <v-col cols=12>
                  <app-button :action="this.nextPage" :title="$t(`button.next`)" style="width:inherit"></app-button>
                  <!-- <v-btn height="auto" @click="step++" color="black" depressed class="primary-button theme-button text-capitalize pa-3 font-weight-bold yellow--text subtitle-1">{{ $t(`button.next`) }}
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-form>
          </v-window-item>
          <v-window-item :value="1" style="height:500px">
            <v-form ref="registerForm2">
              <v-card-text class="ma-0 pa-0">
                <label class="input-field-label ma-0 pb-2 text-capitalize d-block">{{ $t(`field.phoneNumber`) }}</label>
                <v-row no-gutters>
                  <v-col cols=5>
                    <v-select elevation="2" class="input-field mr-2" item-text="text" item-value="value"
                              :items="mobileNumberPrefixList" dense outlined></v-select>
                  </v-col>
                  <v-col cols=7>
                    <app-form-field :enabledLabel=false v-model.trim="registerObj.mobileNumber"
                                    :hint="$t(`fieldHint.fillUpHere`)"
                                    :rules="validator.mobileNumberRules(defaultLanguage)"></app-form-field>
                  </v-col>
                </v-row>
                <label class="input-field-label ma-0 pb-2 text-capitalize d-block">OTP no</label>
                <v-row no-gutters>
                  <v-col cols=5>
                    <v-btn height="auto" @click="this.requestSmsVerificationCode" color="black" depressed
                           class="primary-button theme-button text-capitalize pa-2 font-weight-bold yellow--text subtitle-2">
                      {{ $t(`button.requestOtp`) }}
                    </v-btn>
                  </v-col>
                  <v-col cols=7>
                    <app-form-field :enabledLabel=false v-model.trim="registerObj.verificationCode"
                                    :hint="$t(`fieldHint.fillUpHere`)"></app-form-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols=12>
                    <app-form-field :enabledLabel="true" v-model.trim="registerObj.currency"
                                    :label="$t(`field.currency`)" :hint="$t(`fieldHint.currency`)" type="select"
                                    :rules="validator.dropDownListRules()" :items="currencyList"></app-form-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols=12>
                    <app-form-field :enabledLabel=true v-model.trim="registerObj.referralCode"
                                    :label="$t(`field.referralCode`)" :hint="$t(`fieldHint.optional`)"
                                    :rules="validator.referralCodeRules()" :readonly="isReferralCodeReadonly"
                                    :isOptional="true"></app-form-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-checkbox class="mt-0 pt-0" :rules="validator.requiredRules()">
                      <template v-slot:label>
                        <p class="caption pt-2 mb-0">
                          <label>{{ $t(`message.registerDisclaimer_1`) }}</label>
                          <label class="secondary&#45;&#45;text font-weight-bold" style="cursor:pointer"
                                 @click="openRegisterTncDialog">{{ $t(`message.registerDisclaimer_2`) }}</label>
                        </p>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <app-button :action="this.register" :title="$t(`button.register`)"
                                style="width:inherit"></app-button>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-form>
          </v-window-item>
          <!-- <v-window-item :value="2">
              <div class="pa-4 text-center">
                  <v-img class="mb-4" contain height="128" src="https://cdn.vuetifyjs.com/images/logos/v.svg"></v-img>
                  <h3 class="text-h6 font-weight-light mb-2">
                      Welcome to Vuetify
                  </h3>
                  <span class="text-caption grey--text">Thanks for signing up!</span>
              </div>
          </v-window-item> -->
          <v-row no-gutters>
            <v-col cols=12 class="mt-5">
              <v-item-group v-model="step" class="text-center" mandatory>
                <v-item v-for="n in regSteps" :key="`btn-${n}`" v-slot="{ active, toggle }">
                  <v-btn color="white" class="ma-2 resgiter-dialog-pagination"
                         active-class="resgiter-dialog-pagination-active" x-small icon :input-value="active"
                         @click="toggle" :disabled="true">
                    {{ n }}
                  </v-btn>
                </v-item>
                <v-item v-slot="{ active, toggle }">
                  <v-btn active-class="resgiter-dialog-pagination-active" icon :input-value="active" :disabled="true">
                    <v-icon>check_circle</v-icon>
                  </v-btn>
                </v-item>
              </v-item-group>
            </v-col>
          </v-row>
        </v-window>
        <!-- <p class="mt-5 text-center body-1">
                {{ $t(`message.alreadyHaveAccount`) }}
                <v-btn text height="auto" @click="openLogin" class="pa-0 text-uppercase font-weight-bold secondary--text">{{ $t(`label.signIn`) }}
                </v-btn>
            </p> -->
        <div v-if="step == 0">
          <v-row no-gutters class="py-6">
            <v-col cols="12">
              <v-divider class="dialog-divider"></v-divider>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols=12>
              <label class="input-field-label ma-0 pb-1 d-block title_color2--text">
                {{ $t(`message.alreadyHaveAccount`) }}</label>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-btn height="auto" @click="openLogin" color="black" depressed
                     class="primary-button theme-button text-capitalize pa-3 font-weight-bold yellow--text subtitle-1">
                {{ $t(`button.login`) }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import stringFormat from 'string-format'
import {
  formValidator,
  uiHelper,
  locale
} from '@/util'
import {
  MEMBER_REGISTER,
  MEMBER_SMS_REQUEST,
  MEMBER_SMS_VERIFY,
  MEMBER_GET_SMS_REQUIREMENT
} from '@/store/member.module'
import {
  SHARED
} from '@/constants/constants'
import {
  errorCodeHelper
} from "@/util/error-code-helper";
import {
  MEMBER_RESET_SMS_VERIFICATION
} from "@/store/member.module";

export default {
  name: 'appRegister',
  mounted() {
    this.$refs.registerForm.reset()
    this.checkAffiliateReferralCode()
    this.checkPhoneVerificationRequirements()
  },
  props: {
    openLoginDialog: {
      type: Function,
      required: true,
      default: () => {
      }
    }
  },
  data: () => ({
    defaultLanguage: SHARED.DEFAULT_LANGUAGE,
    registerTncUrl: '',
    registerTncDialog: {
      show: false,
      title: locale.getMessage(`label.registerAccountAgreement`)
    },
    validator: formValidator,
    stringFormat: stringFormat,
    isReferralCodeReadonly: false,
    registerObj: {
      memberCode: '',
      password: '',
      currency: '',
      email: '',
      mobileNumber: '',
      verificationCode: '',
      line: '',
      referralCode: '',
      isEarlyBird: '0',
      domain: '',
      language: '',
      platform: ''
    },
    confirmedPassword: '',
    step: 0,
    regSteps: 2,
    mobileNumberPrefixList: [{
      text: "+91",
      value: "91"
    },
      {
        text: "+880",
        value: "880"
      }
    ],
    currencyList: ['INR', 'BDT']
  }),
  computed: {
    smsRequired() {
      return this.$store.state.member.smsVerificationRequired.required
    },
    smsVerificationSent() {
      return this.$store.state.member.smsVerificationSent.success
    },
    smsVerificationResult() {
      return this.$store.state.member.smsVerificationResult.success
    },
    smsVerificationError() {
      return this.$store.state.member.smsVerificationResult.message
    },
    smsRequestError() {
      return this.$store.state.member.smsVerificationSent.message
    },
    smsRequestAvailable() {
      return this.$store.state.member.smsVerificationSent.countDownTimer <= 0
    },
    smsCountdownTimer() {
      return this.$store.state.member.smsVerificationSent.countDownTimer.toString()
    }
  },
  watch: {
    smsRequestError() {
      if (this.$store.state.member.smsVerificationSent.complete && !this.$store.state.member.smsVerificationSent.success) {
        this.errorDialog(this.$store.state.member.smsVerificationSent.code)
      }
    }
  },
  methods: {
    errorDialog(errorCode) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.register`)
      dialog.dialogXButton = null
      dialog.message.push(errorCodeHelper.getErrorCodeDesc(errorCode))
      dialog.button.push({
        title: locale.getMessage(`label.close`),
        action: () => this.closePageDialog()
      })
      this.openPageDialog(dialog)
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$store.dispatch(`${MEMBER_RESET_SMS_VERIFICATION}`)
      this.$eventHub.$emit('close-page-dialog', true)
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    checkPhoneVerificationRequirements() {
      this.$store.dispatch(`${MEMBER_GET_SMS_REQUIREMENT}`)
    },
    openRegisterTncDialog() {
      this.registerTncUrl = `${process.env.BASE_URL}static/html/other/${uiHelper.getLanguage()}/register-tnc.html`
      this.registerTncDialog.show = true
      this.registerTncDialog.dialogXButton = this.closePromotionTncDialog
    },
    closeRegisterTncDialog() {
      this.registerTncDialog.show = false
    },
    openLogin() {
      this.$refs.registerForm1.reset()
      this.$refs.registerForm2.reset()
      this.openLoginDialog()
    },
    register() {
      // if (this.smsRequired && !this.smsVerificationSent && !this.smsVerificationResult.success) {
      //     this.requestSmsVerificationCode()
      //     return
      // }
      if (this.$refs.registerForm2.validate()) {
        //this.registerObj.currency = uiHelper.getCurrency()
        this.registerObj.platform = uiHelper.getPlatform()
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_REGISTER}`, {
          registerObj
        })
      }
    },
    requestSmsVerificationCode() {
      this.$store.state.member.smsVerificationSent.success = false
      if (this.$refs.registerForm2.validate()) {
        this.registerObj.domain = uiHelper.getHostname()
        this.registerObj.language = uiHelper.getLanguage()
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_REQUEST}`, {
          registerObj
        })
      }
    },
    async verifySmsCode() {
      if (this.$refs.registerForm.validate()) {
        let registerObj = this.registerObj
        this.$store.dispatch(`${MEMBER_SMS_VERIFY}`, {
          registerObj
        })
      }
    },
    checkAffiliateReferralCode() {
      let affilaiteCode = uiHelper.getLocalStorage('affReferralCode')
      if (affilaiteCode != null && affilaiteCode != '') {
        this.registerObj.referralCode = affilaiteCode
        this.isReferralCodeReadonly = true
      } else {
        let affiliateDomain = SHARED.AFFILIATE_DOMAIN.find(x => x.domain.toLowerCase() == window.location.host.toLowerCase())
        if (affiliateDomain != null && affiliateDomain != undefined) {
          this.registerObj.referralCode = affiliateDomain.affiliateCode
          this.isReferralCodeReadonly = true
        } else {
          this.registerObj.referralCode = ''
          this.isReferralCodeReadonly = false
        }
      }
    },
    nextPage() {
      if (this.$refs.registerForm1.validate())
        this.step++
    }
  }
}
</script>

<style lang="scss">
.bg_home {
  background-color: #EBEBEB;
}
</style>